import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sprintDates: [],
};

export const sprintDatesSlice = createSlice({
  name: 'sprintDates',
  initialState,
  reducers: {
    getSprintDatesData: (state, action) => {
        state.sprintDatesData = 
[{"sprint":"ES3","start_date":"01-Apr-2024","mid_quarter_date":"13-May-2024","end_quarter_date":"08-Jul-2024"},
 {"sprint":"ES4","start_date":"01-Jul-2024","mid_quarter_date":"12-Aug-2024","end_quarter_date":"07-Oct-2024"},
 {"sprint":"AS4A","start_date":"08-Jul-2024","mid_quarter_date":"N/A","end_quarter_date":"05-Aug-2024"},
 {"sprint":"AS4B","start_date":"05-Aug-2024","mid_quarter_date":"N/A","end_quarter_date":"02-Sep-2024"},
 {"sprint":"AS4C","start_date":"02-Sep-2024","mid_quarter_date":"N/A","end_quarter_date":"07-Oct-2024"},
 {"sprint":"ES5","start_date":"07-Oct-2024","mid_quarter_date":"11-Nov-2024","end_quarter_date":"06-Jan-2025"},
 {"sprint":"AS5A","start_date":"07-Oct-2024","mid_quarter_date":"N/A","end_quarter_date":"04-Nov-2024"},
 {"sprint":"AS5B","start_date":"04-Nov-2024","mid_quarter_date":"N/A","end_quarter_date":"02-Dec-2024"},
 {"sprint":"AS5C","start_date":"02-Dec-2024","mid_quarter_date":"N/A","end_quarter_date":"06-Jan-2025"},
 {"sprint":"ES6","start_date":"06-Oct-2025","mid_quarter_date":"10-Feb-2025","end_quarter_date":"07-Apr-2025"},
 {"sprint":"ES6A","start_date":"06-Oct-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Feb-2025"},
 {"sprint":"ES6B","start_date":"03-Feb-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Mar-2025"},
 {"sprint":"ES6C","start_date":"03-Mar-2025","mid_quarter_date":"N/A","end_quarter_date":"07-Apr-2025"},
 {"sprint":"ES7","start_date":"07-Apr-2025","mid_quarter_date":"19-May-2025","end_quarter_date":"07-Jul-2025"},
 {"sprint":"ES7A","start_date":"07-Apr-2025","mid_quarter_date":"N/A","end_quarter_date":"05-May-2025"},
 {"sprint":"ES7B","start_date":"05-May-2025","mid_quarter_date":"N/A","end_quarter_date":"02-Jun-2025"},
 {"sprint":"ES7C","start_date":"02-Jun-2025","mid_quarter_date":"N/A","end_quarter_date":"07-Jul-2025"},
 {"sprint":"ES8","start_date":"07-Jul-2025","mid_quarter_date":"18-Aug-2025","end_quarter_date":"06-Oct-2025"},
 {"sprint":"ES8A","start_date":"07-Jul-2025","mid_quarter_date":"N/A","end_quarter_date":"04-Aug-2025"},
 {"sprint":"ES8B","start_date":"04-Aug-2025","mid_quarter_date":"N/A","end_quarter_date":"01-Sep-2025"},
 {"sprint":"ES8C","start_date":"01-Sep-2025","mid_quarter_date":"N/A","end_quarter_date":"06-Oct-2025"},
 {"sprint":"ES9","start_date":"06-Oct-2025","mid_quarter_date":"17-Nov-2025","end_quarter_date":"05-Jan-2026"},
 {"sprint":"ES9A","start_date":"06-Oct-2025","mid_quarter_date":"N/A","end_quarter_date":"03-Nov-2025"},
 {"sprint":"ES9B","start_date":"03-Nov-2025","mid_quarter_date":"N/A","end_quarter_date":"01-Dec-2025"},
 {"sprint":"ES9C","start_date":"01-Dec-2025","mid_quarter_date":"N/A","end_quarter_date":"05-Jan-2026"},
 {"sprint":"Non-sprint Release","start_date":"N/A","mid_quarter_date":"N/A","end_quarter_date":"N/A"},
]
    },
  },
});

export const { getSprintDatesData } = sprintDatesSlice.actions;
export const selectSprintDatesData = (state) => state.sprintDates.sprintDatesData;

export default sprintDatesSlice.reducer;
