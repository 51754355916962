import React, { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getCslData, selectCslData, deleteCslData, updateCslData, getLoadingState} from '../../redux/slices/cslSlice';
import { OrcaTable } from '../../Components/common/OrcaTable';
import store from '../../redux/store';
import { LdsModal, useLdsModal, LdsButton, LdsTextField, LdsSelect } from "@elilillyco/ux-lds-react";
import { QuillTextArea} from "../../Components/common/OrcaTextInput";
import { Formik, Form } from "formik";
import { CILFormFields } from '../../Components/requests/RequestForm';

export const ConsolidatedInsightLibraryAdmin = () => {
  const data = useSelector(selectCslData);
  const loading = useSelector(getLoadingState);

  const { isModalOpen, setIsModalOpen } = useLdsModal();
  const [ modalHeading, setModalHeading ] = useState('Update Insight Status Admin');
  const [ formData, setFormData ] = useState({});

  useEffect(() => {
    store.dispatch(getCslData());
  }, []);

  const actionButtons = [
    {
      iconName: 'PencilSimpleLineFill',
      iconLabel: 'Edit Insight Status',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Update Insight Status');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => userRole === 'Admin',
    },
    {
      iconName: 'TrashSimpleFill',
      iconLabel: 'Edit',
      onClick: (row) => {
        let newrow = { ...row };
        setFormData(newrow);
        setModalHeading('Delete Insight Status');
        setIsModalOpen(true);
      },
      isVisible: (userRole, row) => {return userRole === 'Admin' && row.status === 'Draft'},
    }
  ];

  const DeleteInsightForm = ({ formData }) => {

    return (
      <>
        <div className='box'>
        {"Are you sure you want to delete \""}{formData.insight_key}{"\"?"}
        </div>
        { <LdsButton id="yesButton" value="delete" label="Delete" name="submit" style={{marginBottom:'10px', marginTop:'20px'}} onClick={() => deleteSubmit(formData)} type='submit'>Delete</LdsButton> }
        { <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{marginBottom:'10px', marginTop:'20px', float: 'right'}} onClick={() => setIsModalOpen(false)} type='submit'>Cancel</LdsButton> }
      </>
    );
  }

  const deleteSubmit = (formData) => {
    store.dispatch(deleteCslData(formData));
    setIsModalOpen(false);
  };

  const UpdateInsightForm = ({ formData }) => {

    return (
      <Formik
        initialValues={formData}
        onSubmit={(values, { setSubmitting }) => {
          //setSubmitting(true);
          //updateSubmit(values);
          //setSubmitting(false);
        }}
        enableReinitialize
      >
        {({ isSubmitting, values, handleChange, setFieldValue }) => (
          <Form>
            <div className='box'>
              <span className="more"><LdsTextField label="Insight Key" name="insight_key" value={values.insight_key} onChange={handleChange} disabled={false} style={{ color: 'inherit', marginBottom:'10px', marginTop:'10px' }} /></span>
              <LdsSelect label="Insight Status" name="insight_status_dropdown" value={values.insight_status} options={[{ value: "empty", label: "" },{ value: "Request Submitted", label: "Request Submitted" }, { value: "Production - Paused", label: "Production - Paused" }, { value: "Production - Active", label: "Production - Active" }, { value: "Paused in Dev", label: "Paused in Dev" }, { value: "In Development", label: "In Development" }, { value: "Retired", label: "Retired" }]} style={{ color: 'inherit', marginBottom:'10px', marginTop:'10px' }} />
              <LdsTextField label="Card Number" name="cardnumber" value={values.cardnumber} onChange={handleChange} disabled={false} style={{ color: 'inherit', marginBottom:'10px', marginTop:'10px' }} />
              <CILFormFields values={values} editable={true} setFieldValue={setFieldValue} formValues={formData} setFormValues={setFormData} />
              <br className="more"/>
              <hr className='more' />
              <LdsButton id="yesButton" value="delete" label="Delete" name="submit" style={{ marginBottom:'10px', marginTop:'20px' }} type="submit" onClick={() => updateSubmit(values)}>Submit</LdsButton>
              <LdsButton id="noButton" value="cancel" label="Cancel" name="submit" style={{ marginBottom:'10px', marginTop:'20px', float: 'right' }} onClick={() => setIsModalOpen(false)}>Cancel</LdsButton>
            </div>
          </Form>
        )}
      </Formik>
    );
  }

  const updateSubmit = (formData) => {
    let updateData = { ...formData };
    const newVal = document.getElementsByName('insight_status_dropdown')[0].value;
    updateData.insight_status = newVal;
    if (Array.isArray(updateData['sales_force_team'])) {
      updateData['sales_force_team'] = updateData['sales_force_team'].join(';');
    }
    if (Array.isArray(updateData['insight_graph_applicable_for_sleeve'])) {
      updateData['insight_graph_applicable_for_sleeve'] = updateData['insight_graph_applicable_for_sleeve'].join(';');
    }
    for (let field of ['cloud_tag', 'br_insight_text', 'br_suggestion_text']) {
      if (updateData[`${field}_delta_obj`] != null) {
        updateData[`${field}`] = JSON.stringify(updateData[`${field}_delta_obj`]);
        delete updateData[`${field}_delta_obj`];
      }
  }
    /*if (["Active", "Paused", "Retired"].includes(updateData.status)) {*/
      store.dispatch(updateCslData(updateData));
    /*}*/
    setIsModalOpen(false);
  };

  return (
    <>
    <LdsModal
      modalId="editInsightAdminModal"
      open={isModalOpen}
      setModalOpen={setIsModalOpen}
      heading={modalHeading || "Update Insight Status"}
      persistent
    >
      <LdsButton
        clearDefaultClasses
        className="lds-button-clear-style lds-modal-close-btn focus icon"
        onClick={() => setIsModalOpen(false)}
        icon="X"
        iconOnly
      />
      {modalHeading === 'Update Insight Status' && <UpdateInsightForm formData={formData}/>}
      {modalHeading === 'Delete Insight Status' && <DeleteInsightForm formData={formData}/>}
    </LdsModal>
    <OrcaTable loading={loading} actionButtons={actionButtons} rows={data} showFilters title='Consolidated Insights Library Management' filterColumns={['capability', 'insight_key', 'requester', 'brand', 'indication', 'insight_category', 'text_type', 'insight_status']} displayKey={{'request_key':{'display': true},'insight_key':{'display': true}, 'requested_sprint':{'display': true}, 'br_insight_type':{'display': true}, 'cardnumber':{'display':true, 'minWidth':'100px'}, 'insight_status':{'display': true}, 'capability':{'display': true}, 'brand':{'display': true}, 'indication':{'display': true}, 'insight_category':{'display': true, 'minWidth': '400px'}, 'ta_priority':{'display': true, 'minWidth':'250px'}, 'business_rule':{'display': true, 'minWidth': '400px'}, 'insight_graph_metric_key':{'display': true, 'minWidth': '250px'}, 'bia_metrics':{'display': true, 'minWidth':'400px'}, 'bia_metric_based_on':{'display': true, 'minWidth':'250px'}, 'other_metrics':{'display': true, 'minWidth':'400px'}, 'trigger_criteria':{'display': true, 'minWidth':'400px'},'bia_trigger_based_on':{'display':true,'minWidth':'250px'}, 'new_metrics_ingest':{'display': true, 'minWidth':'240px'}, 'cloud_tag':{'display': true, 'minWidth':'200px'}, 'segment':{'display': true}, 'refresh_freq':{'display': true}, 'text_type':{'display': true}, 'br_insight_text':{'display': true, 'minWidth':'400px'}, 'br_suggestion_text':{'display': true, 'minWidth':'400px'}, 'fcap':{'display': true, 'minWidth':'100px'}, 'status': { 'display': true, 'title': 'Request Status' }, 'sales_force_team':{'display': true, 'minWidth':'300px'}}} />
    </>
  );
};
